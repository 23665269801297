import React from 'react';
import GameContext from './GameContext';
import * as firebase from 'firebase/app';
// import _ from 'lodash';
import 'firebase/database';
import 'firebase/auth';
import LoadingScreen from '../components/global/LoadingScreen';

class GameData extends React.Component {

    static contextType = GameContext;

    constructor(){
        super();

        this.busy = false;

        this.start_game = async () => {
            return new Promise((resolve, reject) => {
                
                var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);

                user_ref.once('value').then((snap) => {
                    var res = snap.val();

                    // Check if start time is already set.
                    if(typeof res.start_time !== 'undefined'){
                        this.resume_game(res);
                        // resolve();
                        return;
                    };

                    // Set new game data
                    var now = new Date().getTime();

                    var new_user_data = {
                        ...res,
                        game_state: 'ingame',
                        progress: 0,
                        start_time: now,
                        screens: [
                            {
                                start_time: now,
                                hint_used: false,
                            }
                        ]
                    }

                    user_ref.update(new_user_data).then(() => {
                        this.setState(new_user_data)
                    })

                })
            })
        }

        this.resume_game = (res) => {
            this.setState({
                ...this.state,
                ...res
            })
        }

        this.next_screen = () => {
            if(this.busy) return;
            this.busy = true;
            return new Promise((resolve, reject) => {

                var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);

                user_ref.once('value').then((snap) => {
                    var res = snap.val();

                    // Check if final screen
                    if((res.progress + 1) === this.props.game.routes[res.route].length){
                        console.log('last screen');
                        this.busy = false;
                        this.end_game();
                        // resolve();
                        return;
                    }

                    // Set new game data
                    var now = new Date().getTime();

                    res.screens[res.progress].end_time = now;
                    res.screens.push({
                        start_time: now,
                        hint_used: false,
                    })

                    var new_user_data = {
                        ...res,
                        progress: res.progress + 1,
                    }


                    user_ref.update(new_user_data).then(() => {
                        this.setState(new_user_data, () => {
                            this.busy = false;
                        })
                    })


                })
            })            
        }

        this.handle_time_up = () => {
            this.end_game(true);
        }

        this.set_hint_used_at_screen = () => {
            return new Promise((resolve, reject) => {
                var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);  

                user_ref.once('value').then((snap) => {
                    var res = snap.val();

                    res.screens[res.progress].hint_used = true;

                    var new_user_data = {
                        ...res,
                    }

                    user_ref.update(new_user_data).then(() => {
                        resolve(new_user_data);
                    })
                });
            })
        }
        this.save_team_data = (team_name) => {
            return new Promise((resolve, reject) => {
                var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);
                user_ref.once('value').then((snap) => {
                    var res = snap.val();
    
                    var new_user_data = {
                        ...res,
                        team_name: team_name,
                    }
                    user_ref.update(new_user_data).then(() => {
                        resolve(new_user_data);
                    })
                });            
            })
        }
        this.set_game_state = (state) => {
            var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);
            user_ref.once('value').then((snap) => {
                var res = snap.val();

                var new_user_data = {
                    ...res,
                    game_state: state,
                }

                
                user_ref.update(new_user_data).then(() => {
                    this.setState(new_user_data)
                })

            });
        }

        this.end_game = (failed) => {
            console.log(this.busy);
            if(this.busy) return;
            this.busy = true;
            
            if(typeof failed === 'undefined'){
                failed = false;
            }
            return new Promise((resolve, reject) => {
                var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);
                var game_ref = firebase.database().ref(`games/${this.props.game.config.game_id}/scores`);

                user_ref.once('value').then((snap) => {
                    var res = snap.val();

                    // Check if start time is already set.
                    if(typeof res.end_time !== 'undefined') reject('Game already ended');

                    var now = new Date().getTime();

                    res.screens[res.progress].end_time = now;

                    var new_user_data = {
                        ...res,
                        end_time: now,
                        time: now - res.start_time,
                        game_state: 'postgame',
                        failed: failed,
                    }

                    user_ref.update(new_user_data).then(() => {
                        
                        game_ref.push({
                            uid: this.props.user.user.uid,
                            team_name: new_user_data.team_name,
                            team_picture: 'https://cateringopmaat.nl/wp-content/uploads/2017/08/blank-profile-picture-973460_1280-600x600.png',
                            time: now - res.start_time,
                        }, (err) => {
                            if(err) console.log(err);

                            localStorage.removeItem('active_game');
                            this.setState(new_user_data, () => {
                                this.busy = false;
                            })
                        })

                    })
                    
                })
            })
        }


        this.state = {
            progress: 0,
            start_time: null,
            end_time: null,
            screens: [],

            //actions
            next_screen: this.next_screen,
            start_game: this.start_game,
            handle_time_up: this.handle_time_up,
            set_hint_used_at_screen: this.set_hint_used_at_screen,
            set_game_state: this.set_game_state,
            save_team_data: this.save_team_data
        }
    }

    componentDidMount(){
        console.log(this.props.user);
        var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames/${this.props.game.config.game_id}`);
        user_ref.once('value').then((snap) => {
            var res = snap.val();
            console.log(res)
            if(res !== null){
                this.resume_game(res);
            }
        })
    }

    render(){
        if(this.state.loading) return <LoadingScreen />

        return(
            <GameContext.Provider value={this.state} >
                {this.props.children}
            </GameContext.Provider>
        )
    }
}

export default GameData;