import React from 'react';
import LoadingScreen from '../../components/global/LoadingScreen';

class PreGame extends React.Component {

    constructor(){
        super();

        this.state = {
            loading: true,
            step: 0,
            team_name: '',
            error: null,
        }
    }

    componentDidMount(){
        // Check for pre screens
        var has_pre_screens = false;
        // start game when there are no pre screen.
        if(has_pre_screens === false) return;
    }

    _onValueChange(e){
        const value = e.target.value
        const name = e.target.name

       this.setState({[name]: value})
    }


    next_step(){
        this.setState({step: this.state.step + 1});
    }
    async start_game(){
        if(this.state.team_name === ''){
            alert('Teamnaam is nog leeg.')
            return;
        }
        await this.props.data.save_team_data(this.state.team_name);
        this.props.data.start_game();
    }

    render(){
        switch(this.state.step){
            case 0 :
                return(
                    <div className="container intro">
                        <div className="intro--2">
                            <h1>Nice! Welkom.</h1>
                            <p>Voer een teamnaam in en druk op start als jullie klaar zijn om te beginnen</p>
                            <div className="input input--text">
                                <input type="text" name="team_name" value={this.state.team_name} onChange={(e) => this._onValueChange(e)} placeholder="Een teamnaam"/>   
                                { this.state.error !== null 
                                    ? <div className="error">{this.state.error}</div>         
                                    : null
                                }
                            </div>
                            <div className="btn--group">
                                <button className="btn btn-primary" onClick={() => this.start_game()}>Start</button>
                            </div>
                        </div>
                    </div>
                )
            case 1 :
                return(
                    <div className="container intro">
                        <div className="intro--2">
                            <h1>Nice! Welkom.</h1>
                            <p>Voor een teamnaam in en druk op start als jullie klaar zijn om te beginnen</p>
                            <div className="input input--text">
                                <input type="text" name="team_name" value={this.state.team_name} onChange={(e) => this._onValueChange(e)} placeholder="Een teamnaam"/>   
                                { this.state.error !== null 
                                    ? <div className="error">{this.state.error}</div>         
                                    : null
                                }
                            </div>
                            <div className="btn--group">
                                <button className="btn btn-primary" onClick={() => this.next_step()}>Verder</button>
                            </div>
                        </div>
                    </div>                   
                )
        }

        return null;
    }
}

export default PreGame;