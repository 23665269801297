import React from 'react';
import MenuBar from '../components/global/MenuBar';
import OldScores from '../components/scores/OldScores';


class Dashboard extends React.Component {

    constructor(){
        super();

        this.state = {
            active_game: localStorage.getItem('active_game'),
        }
    }

    componentDidMount(){
        console.log(this.state)
    }
    render(){
        return(
            <React.Fragment>
                <MenuBar /> 
                <div className="dashboard container">
                    <OldScores user={this.props.user} />
                </div>
            </React.Fragment>
            
        )
    }
}

export default Dashboard;