import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconMenu } from '../../assets/images/icon_menu.svg';


class GameMenu extends React.Component{
    constructor(){
        super();
        this.state = {
            open: false,
        }
    }
    _toggleMenu(){
        if(this.state.open) document.body.classList.remove('menu--open');
        if(!this.state.open) document.body.classList.add('menu--open');
        this.setState({open: !this.state.open});
    }
    render(){
        let menu_class = this.state.open ? 'menu menu--open' : 'menu';
        return(
            <React.Fragment>
                <div className={menu_class}>
                    <nav>
                        <a className="nav-link" href="tel:0638967113">Ik wil hulp</a>
                        <Link to="/logout" className="nav-link">Uitloggen</Link>
                    </nav>
                    <div className="overlay" onClick={() => this._toggleMenu()} />
                </div>
                <button className="btn btn--menu" onClick={() => this._toggleMenu()}><IconMenu /></button>
            </React.Fragment>
        )
    }
}

export default GameMenu;