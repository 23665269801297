import React from 'react';
import NextButton from '../global/NextButton';

class Video extends React.Component{
    render(){
        var d = this.props.screen.templateData;
        return (
            <div className="template template--video">
                <img src={d.video_url} className="gif" />
                <h1>{d.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: d.body }} />
                <NextButton {...this.props} />
            </div>
        )
    }
}

export default Video;