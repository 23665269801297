import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../components/global/LoadingScreen';

// import Logo from '../assets/images/get-lost-logo.png';

class Login extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            redirect: props.user.isAuthenticated ? true : false,
        }
    }

    componentDidMount(){
        this.loginAsGuest();
    }

    loginAsGuest(){
        this.setState({loading: true}, () => {
            this.props.user.signInAnonymously();
        })
    }

    render(){
        if(this.state.redirect) return <Redirect to="/intro" />
        if(this.state.loading) return (<LoadingScreen />);

        return(
            <div className="container">
                <button onClick={() => this.loginAsGuest()} className="btn primary">Inloggen</button>
            </div>
        )


    }
}

export default Login