import React from 'react';
import NextButton from '../global/NextButton';
import YouTube from 'react-youtube';
import { ReactComponent as IconRepeat } from '../../assets/images/icon_repeat.svg';

class FullscreenYoutubeVideo extends React.Component {

    constructor(){
        super();
        this.state = {
            video_ended: false,
        }
    }

    _onEnd(event){
        if(typeof this.props.screen.templateData.body == 'undefined'){
            this.props.next();
            return;
        }
        this.setState({video_ended: true})
    }
    _replay(){
        var d = this.props.screen.templateData;
        this.setState({video_ended: false}, () => {
            // var elem = document.getElementById(d.video_id);
            // console.log(elem);
            // elem.playVideo();
        })
    }

    render(){
        var d = this.props.screen.templateData;

        if(this.state.video_ended){
            return(
                <div className="template template--text">
                    <h1>{d.title}</h1>
                    <p>{d.body}</p>

                    <div className="btn--group">
                        <button className="btn btn--replay" onClick={() => this._replay()}><IconRepeat />Replay</button>
                        <NextButton {...this.props} />
                    </div>
                </div>
            )
        }


        // Render fullscreen video
        var opts = {
            width: '100%',
            height: '100%',
            playerVars: {
                modestbranding: 0,
                controls: 0,
                autoplay: d.autoplay ? 1 : 0,
                rel: 0,
            }
        }
        return (
            <div className="template template--fullscreen_video">
                <YouTube 
                    videoId={d.video_id}
                    opts={opts}
                    onEnd={(event) => this._onEnd(event)}
                    id={d.video_id}
                    containerClassName="template--fullscreen_video--youtube"
                />
            </div>
        )
    }
}

export default FullscreenYoutubeVideo;