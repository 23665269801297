import React from 'react';
import CodeInput from '../global/CodeInput';

class Code extends React.Component {
    constructor(){
        super();
        this.state = {
            to_many_tries: false,
            time: 0,
        }
        this.tries = 0;
        this.punishments = 1;
    }
    
    _handleIncorrect(){
        this.tries = this.tries + 1;

        if(this.tries > 4){
            this.setState({to_many_tries: true, time: (this.punishments * 30)});
            this.punish_int = setInterval(() => {
                if((this.state.time - 1) <= 0){

                    this.setState({time: 0, to_many_tries: false})
                    this.tries = 0;
                    this.punishments = this.punishments + 1
                    clearInterval(this.punish_int);
                    return;
                }

                this.setState({time: this.state.time - 1});

            }, 1000);
        }
    }

    render(){
        const d = this.props.screen.templateData
        if(this.state.to_many_tries){
            return(
                <div className="template template--code template--code--punish">
                    <h2>Oeps, met raden kom je niet ver...</h2>
                    <p>Probeer het over {this.state.time} seconden weer</p>
                </div>
            )
        }
        return(
            <div className="template template--code">
                <h1>{d.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: d.body }} />                
                <CodeInput onCorrect={this.props.next} onIncorrect={() => this._handleIncorrect()} code={d.code} type={d.keyboard_type} />
            </div>
        )
    }
}

export default Code;