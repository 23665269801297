import React from 'react';
import {geolocated} from 'react-geolocated';
import MapContainer from '../global/MapContainer';
import LoadingScreen from '../global/LoadingScreen';

class Location extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            location: true,
            body_viewed: false,
        }
    }

    componentDidMount(){
        if(typeof this.props.screen.templateData.body == 'undefined'){
            this.setState({body_viewed: true});
        }
        var location_available = localStorage.getItem('location_available');
        console.log(location_available);
    }

    _handleNoLocation(){
        localStorage.setItem('location_available', false);
        this.setState({location: false})
    }

    close_body_modal(){
        this.setState({body_viewed: true});
    }

    renderMap(){
        var d = this.props.screen.templateData;  

        if(!this.props.isGeolocationAvailable || this.state.location !== true){
            return(
                <div className="template template--location">
                    <MapContainer location={d.location} next={this.props.next} user_location={false} />
                    <button onClick={this.props.next} className="btn">Verder</button>
                </div>
            )
        }
        if(!this.props.isGeolocationEnabled){
            return(
                <div className="template template--location_unavailable" >
                    <h1>Locatie niet beschikbaar</h1>
                    {/* <p>Available: {this.props.isGeolocationAvailable ? 'true' : 'false'}</p><p>Enabled: {this.props.isGeolocationEnabled ? 'true' : 'false'}</p> */}
                    <p>Is er geen toestemming gevraagd voor je locatie? Bij de instellingen van je browser kun je zien of je dit hebt uitgeschakeld. Herlaad de pagina om het opnieuw te proberen. Is je locatie echt niet beschikbaar, navigeer dan naar de locatie en klik dan op verder.</p>
                    <a href={`http://maps.apple.com/?q=${d.location.latitude},${d.location.longitude},`} className="btn btn-primary">Navigeer naar locatie</a>
                    <button onClick={() => this.props.next()} className="btn">Verder</button>
                </div>
            )
        }
        if(this.props.coords){
            return(
                <div className="template template--location ">
                    <MapContainer location={d.location} next={this.props.next} user_location={true}/>
                </div>
            )
        }
    }

	render(){
        var d = this.props.screen.templateData;
        console.log(this.props)
        
        return(
            <React.Fragment>
                {!this.state.body_viewed ? 
                    <div className="modal__outer">
                        <div className="modal__background" onClick={() => this.close_body_modal()}/>
                        <div className="modal__inner content">
                            <div className="content" dangerouslySetInnerHTML={{ __html: d.body }} />
                            <button className="btn" onClick={() => this.close_body_modal()}>Verder</button>
                        </div>
                    </div>
                    : null }

                {this.renderMap()}
            </React.Fragment>
        )
        return <LoadingScreen />
    }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(Location);