const helpers = {
    get_timestring(time){
        var date = new Date(time);
    
        var hours = date.getHours() - 1;
        hours = ("0" + hours).slice(-2);
    
        var minutes = date.getMinutes();
        minutes = ("0" + minutes).slice(-2);
    
        var seconds = date.getSeconds();
        seconds = ("0" + seconds).slice(-2);
    
        var timestring = `${hours}:${minutes}:${seconds}`;
        return timestring;
    }
}

export default helpers;