import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../components/global/LoadingScreen';

// import Logo from '../assets/images/get-lost-logo.png';

class Logout extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            redirect: false,

        }
    }

    componentDidMount(){
        this.props.user.signOut().then(() => {
            this.setState({redirect: true})
        });  
    }
    render(){
        if(this.state.redirect) return <Redirect to="/" />
        return <LoadingScreen />
    }
}

export default Logout