import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './util/ScrollToTop';
// import PrivateRoute from './util/PrivateRoute';
import { Score, Intro, Login, Game, Logout, Dashboard } from './containers';
import PuzzlePieces from './components/puzzles/PuzzlePieces';
import UserContext from './util/UserContext';
import UserData from './util/UserData';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import LoadingScreen from './components/global/LoadingScreen';

var config = {
    apiKey: "AIzaSyDfYY3Ab0fEdPeJzaZhQUlE1oq9lnfdqh4",
    authDomain: "get-lost-web-app-60598.firebaseapp.com",
    databaseURL: "https://get-lost-web-app-60598.firebaseio.com",
    projectId: "get-lost-web-app-60598",
    storageBucket: "get-lost-web-app-60598.appspot.com",
    messagingSenderId: "291366447793"
};

firebase.initializeApp(config);



class App extends Component {

	constructor(){
		super();
		this.state = {
			loading: true,
			user: null,
			isAuthenticated: false,
			active_game: null,
		}
	}

	componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
			if(user){
				var active_game = localStorage.getItem('active_game');
				this.setState({user: user, loading: false, isAuthenticated: true, active_game: JSON.parse(active_game)}) 
				return;
			}
			
			this.setState({loading: false, isAuthenticated: false,})
        });
	}

	render(){
		
		if(this.state.loading) return <LoadingScreen />
		
		return(
			<Router>
				<UserData user={this.state.user}>
					<ScrollToTop>
						<UserContext.Consumer>
							{value => (
								<Switch>
									<Route exact path="/" component={(props) => (
										this.state.isAuthenticated ? (
											this.state.active_game !== null ? (
											<Redirect to={`/${this.state.active_game.game_slug}?game_id=${this.state.active_game.game_id}`} /> 
											) : (
											<Redirect to="/intro" />
											)
										) : (
											<Redirect to ="/login" />
										)
									)}/>

									{/* <Route exact path="/dashboard" component={(props) => (
										this.state.isAuthenticated ? (
											<Dashboard {...props} user={value} />
										) : (
											<Login {...props} user={value} />
										)
									)} /> */}

									<Route exact path="/login" component={(props) => (
										<Login {...props} user={value} />
									)} />

									<Route exact path="/logout" component={(props) => (
										<Logout {...props} user={value} />
									)} />

									<Route exact path="/score/:id" component={Score} user={value} />

									<Route exact path="/intro" component={(props) => (<Intro {...props} user={value} />)}  />
									
									
									<Route exact path="/the-post-activeren" component={PuzzlePieces} />


									<Route exact path="/:game_slug" component={(props) => (
										<Game {...props} user={value} active_game={this.state.active_game} />
									)} />
								</Switch>
							)}
						</UserContext.Consumer>
					</ScrollToTop>	
				</UserData>	
			</Router>
		)
		
	}
}

export default App;
