import React from 'react';

const LoadingScreen = () => {
    return(
        <div className="loading loading--fullscreen">
            <div className="loader" />
        </div>
    )
}

export default LoadingScreen;