import React from 'react';
import ScrollToTop from '../../util/ScrollToTop';

import Text from './Text';
import Code from './Code';
import Video from './Video';
import Location from './Location';
import TimerReveal from './TimerReveal';
import FullscreenYoutubeVideo from './FullscreenYoutubeVideo';

import LinkPuzzlePieces from './LinkPuzzlePieces';

class TemplateRender extends React.Component {

    constructor(){
        super();
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            window.scrollTo(0, 0);
        }
    }

    templateSwitch(){
        switch(this.props.screen.template){
            case "Text" :
                return <Text {...this.props} />
            case "Location" :
                return <Location {...this.props} />
            case "Code" :
                return <Code {...this.props} />
            case "Video" :
                return <Video {...this.props} />
            case "FullscreenVideo" :
                if(this.props.screen.templateData.video_provider === 'youtube') return <FullscreenYoutubeVideo {...this.props} />
                return null;
            case "Timer" :
                return <TimerReveal {...this.props} /> 
            case "LinkPuzzlePieces" :
                return <LinkPuzzlePieces {...this.props} />
                // return <Text {...this.props} />
            default :
                return <Text {...this.props} />
        }
    }

    render(){
        return(
            <div className="template--outer" data-route-id={this.props.data.route}>
                {this.templateSwitch()}
            </div>
        )
    }
}

export default TemplateRender;