import React from 'react';

import TemplateRender from '../../components/templates/TemplateRender';
import GameMenu from '../../components/global/GameMenu';
import Timer from '../../components/global/Timer';
import Hints from '../../components/global/Hints';
import _ from 'lodash';


class InGame extends React.Component {

    _next(){
        this.props.data.next_screen();
    }

    _timeup(){
        this.props.data.handle_time_up()
    }

    render(){
        const {data, game} = this.props;
        const screen = game.routes[data.route][data.progress];

        return(
            <React.Fragment>
                <Timer {...this.props} screen={screen} timeup={() => this._timeup()} />
                <GameMenu />
                <TemplateRender {...this.props} next={() => this._next()} screen={screen} />
                <Hints {...this.props} screen={screen} />
            </React.Fragment>
        );
    }
}

export default InGame;