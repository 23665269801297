import React from 'react';
import { divIcon } from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import LoadingScreen from '../global/LoadingScreen';

const user_icon = divIcon({
    className: 'leaflet_user_icon',
    html: '<div><div class="icon_inner"></div><div class="icon_pulse"></div></div>',
});

const target_icon = divIcon({
    className: 'leaflet_target_icon',
    html: '<div><div class="icon_inner"></div></div>',
});

class MapContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user_position: null,
            distance: null,
        }

        this.tap_count = 0;

        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }


        this.position = [parseFloat(this.props.location.latitude), parseFloat(this.props.location.longitude)];
        this.bounds = null;


        this.watch = navigator.geolocation.watchPosition((pos) => this.position_succes(pos), this.position_error, options);

    }

    position_succes(pos){
        var lat1 = this.props.location.latitude,
            lon1 = this.props.location.longitude,
            lat2 = pos.coords.latitude,
            lon2 = pos.coords.longitude,
            distance = getDistance(lat1, lon1, lat2, lon2);
        
        // Set initial bounds
        if(this.bounds === null){
            this.bounds = [this.position, [lat2, lon2]];
        }
            
        this.setState({user_position: pos.coords, distance: distance}, () => this.check_position());
    }
    position_error(err){
        console.log(err);
    }

    check_position(){
        var distance = this.state.distance;
        if(distance !== null){

            if(distance < 20){
                navigator.geolocation.clearWatch(this.watch);
                this.props.next();
            }
        }
    }

    marker_spam_continue(){
        this.tap_count = this.tap_count + 1;
        if(this.tap_count > 5){
            this.props.next();
        }
    }

    componentWillUnmount(){
        navigator.geolocation.clearWatch(this.watch);        
    }

    render(){

        if(this.state.user_position === null) return <LoadingScreen />

        const position = [parseFloat(this.props.location.latitude), parseFloat(this.props.location.longitude)];
        const user_position = [this.state.user_position.latitude, this.state.user_position.longitude];

        return(
            <React.Fragment>
                <Map bounds={this.bounds}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        <Marker position={user_position} icon={user_icon} />
                        <Marker position={position} icon={target_icon} />
                </Map>
                <div className="location_meters">
                    <span onClick={() => this.marker_spam_continue()}>Afstand</span><h3>{Math.round(this.state.distance)}m</h3>
                </div>
            </React.Fragment>
        )
    }
}

const getDistance = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c * 1000; // Distance in M
    return d;
}

const deg2rad = (deg) => {
    return deg * (Math.PI/180)
}

export default MapContainer;