import React from 'react';
import LoadingScreen from '../global/LoadingScreen';
import CodeInput from '../global/CodeInput';

import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

class LinkPuzzlePieces extends React.Component {

    constructor(){
        super();

        this.state = {
            loading: true,
            link: '',
        }
    }

    async componentDidMount(){
        // var link = await this.make_link();

        var link = '/the-post-activeren'

        this.setState({link: link, loading: false});
    }

    async make_link(){
        var user_id = firebase.auth().currentUser.uid;
        var user_ref = firebase.database().ref(`/users/${user_id}/savegames/${this.props.data.game_id}/`);
        
        var puzzle_link_id = null;

        while(puzzle_link_id === null){
            var id = this.make_id();
            var exists = await this.check_if_link_exists(id)
            if(exists === false){
                puzzle_link_id = id;
            }
        }

        await user_ref.update({puzzle_link_id: puzzle_link_id, puzzle_link_visits: 0});

        var link = `/a/${puzzle_link_id}`;
        return link;

    }

    async check_if_link_exists(id){
        var links_ref = firebase.database().ref(`/links`);
        
        var link_exists = await links_ref.child(id).once('value').then((snap) => {
            if(snap.exists()) {
                return true;
            }
            return false;
        })

        return link_exists;
    }

    make_id() {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < 5; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    _handleIncorrect(){
        console.log('incorrect');
    }
    render(){
        if(this.state.loading) return <LoadingScreen />
        var d = this.props.screen.templateData;
        return(
            <div className="template template--code template--puzzle_a">
                <h1>{d.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: d.body }} />
                <a className="link--puzzle_a" target="_blank" href={`https://live.getlostgame.nl${this.state.link}`} >live.getlostgame.nl{this.state.link}</a>
                <div className="content">Vul deze oplossing vervolgens hieronder in.</div>
                <CodeInput onCorrect={this.props.next} onIncorrect={() => this._handleIncorrect()} code={d.code} type={d.keyboard_type} />
            </div>
        )
    }
}

export default LinkPuzzlePieces;