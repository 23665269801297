import React from 'react';
import LoadingScreen from '../global/LoadingScreen';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PuzzlePieces extends React.Component {
    constructor(){
        super();

        this.state = {
            puzzle_piece: null,
        }
    }

    componentDidMount(){
        if (!cookies.get('puzzle_a_id')){
            var puzzle_piece = Math.floor(Math.random() * 5) + 1;
            cookies.set('puzzle_a_id', puzzle_piece, { path: '/' });	
        } else {
            var puzzle_piece = cookies.get('puzzle_a_id');
        }

        this.setState({puzzle_piece: puzzle_piece});
    }

    render(){

        if(this.state.puzzle_piece === null) return <LoadingScreen />

        const file = `/games/the-post/puzzlepiece_${this.state.puzzle_piece}.png`;

        return(
            <div className="wrapper puzzle-a">
                <img src={file} alt="puzzelstukje" />
            </div>    
        )
    }
}

export default PuzzlePieces;