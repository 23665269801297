import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/database';
import MenuBar from '../../components/global/MenuBar';
import helper from '../../util/helpers';

class Score extends React.Component {
    constructor(){
        super();
        this.state = {
            scores: [],
            is_score_reveal: true,
            score_reveal_state: 'animate_in',
            loading: true,
        }
    }
    componentDidMount(){

        var game_ref = firebase.database().ref(`games/${this.props.game.config.game_id}`);

        game_ref.child('scores').on('value', (snap) => {
            var res = snap.val();

            var sortable = [];

            var own_score = null;

			for(var key in res){

                // Check if own score
                res[key].self = false;

                if(res[key].uid === this.props.user.user.uid){
                    res[key].self = true;
                    own_score = res[key];
                }

				sortable.push(res[key])
			}

			sortable.sort(function(a, b){
				return new Date(JSON.parse(a.time)) - new Date(JSON.parse(b.time))
            })
            
			if(this.state.scores != sortable){

                var temporary_place = sortable.findIndex((e) => (e.self === true));
                
                this.setState({scores: sortable, temporary_place: temporary_place + 1, own_score: own_score, loading: false})
                
                setTimeout(() => {
                    this.setState({score_reveal_state: 'animate_out'})
                }, 5000)
            }
            
        })
    }
    render(){
        if(this.state.loading) return null;

        var us = this.state.own_score

        var timestring = helper.get_timestring(us.time);


        return(
            <React.Fragment>
            <MenuBar />
            <div className="post_game">
                {this.state.is_score_reveal ? <ScoreReveal {...this.props} {...this.state} /> : null }
                <div className="user_score">
                    <div className="team_picture" style={{backgroundImage: `url(${us.team_picture})`}} />
                    <div className="team_name">{us.team_name}</div>

                    <div className="user_stats">
                        <div>
                            <span>Tijd</span>
                            <h3>{timestring}</h3>
                        </div>
                        <div>
                            <span>Plaats</span>
                            <h3>{this.state.temporary_place}</h3>
                        </div>
                    </div>
                </div>
                <div className="scoreboard">
                    <h3>Scoreboard <span>Tijd</span></h3>
                    <div className="scoreboard_list">{this.state.scores.map((score, index) => (
                        <ScoreItem score={score} key={index} own_score={this.state.own_score} place={index + 1}/>
                    ))}</div>
                </div>
            </div>
            </React.Fragment>
            
        );
    }
}

export default Score;

const ScoreReveal = (props) => {

    var timestring = helper.get_timestring(props.own_score.time);
    
    return(
        <div className={`score_reveal ${props.score_reveal_state}`}>
            {props.data.failed ? 
                <React.Fragment>
                    <h1>{timestring}</h1>
                    <p>Helaas is de klok gestopt, jullie<br />hebben het niet gehaald.</p>
                </React.Fragment>
            :
                <React.Fragment>
                    <span>Jullie tijd</span>
                    <h1>{timestring}</h1>
                    <p>Je weet pas of je hebt gewonnen als iedereen het spel heeft afgerond.</p>
                </React.Fragment>
            }
        </div>
    )
}


const ScoreItem = ({score, own_score, place}) => {

    var timestring = helper.get_timestring(score.time)
    var item_class = score.uid === own_score.uid ? 'scoreboard_item scoreboard_item--self' : 'scoreboard_item';

    return(
        <div className={item_class}>
            <div className="team_place">{place}</div>
            <div className="team_picture" style={{backgroundImage: `url(${score.team_picture})`}} />
            <div className="team_name">{score.team_name}</div>
            <div className="team_score">{timestring}</div>
        </div>
    )
}

