import React from 'react';
import { Redirect } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/database';

import LoadingScreen from '../../components/global/LoadingScreen';
import PreGame from './PreGame';
import InGame from './InGame';
import PostGame from './PostGame';
import Score from './Score';
import qs from 'query-string';

import GameContext from '../../util/GameContext';
import GameData from '../../util/GameData';

class Game extends React.Component {

    constructor(){
        super();

        this.state = {
            loading: true,
            incorrect_game_id: false,
            game: null,
        }
    }

    componentDidMount(){
        // Get game id via url or get param
        this.getGameId()
            .then(game_id => this.getGame(game_id).then(game => this.setState({game: game, loading: false})))
            .catch(() => {
                // No id availibale
                this.setState({incorrect_game_id: true, loading: false})      
            });

        // Get game from 
    }

    getGame(game_id){
        return new Promise((resolve, reject) => {
            var json;
            try {
                json = require(`../../assets/data/game_${game_id}.json`);
            }
            catch(e) {
                if(e.code === 'MODULE_NOT_FOUND'){
                    // Game bestaat niet, handle error
                    return;
                };
                json = null;
            }

            if(json !== null) resolve(json);
        })
    }

    getGameId(){
        return new Promise((resolve, reject) => {
            // check if game_id is in url
            var game_id_from_url = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).game_id;
            if(typeof game_id_from_url !== 'undefined'){
                resolve(game_id_from_url);
            }

            // get game id from slug
            var slug_without_slashes = this.props.location.pathname.replace(/\\/g, '')
            var link_ref = firebase.database().ref(`links/${slug_without_slashes}`);
            link_ref.once('value').then((snap) => {
                var res = snap.val();
                
                if(typeof res.game_id !== 'undefined'){
                    resolve(res.game_id);
                }
            })
        })
    }


    renderGame(data){
        console.log(data);
        switch(data.game_state){
            case('pregame'):
                return(<PreGame data={data} game={this.state.game} />)
            case('ingame'):
                return(<InGame data={data} game={this.state.game} />)
            case('postgame'):
                return(<PostGame data={data} game={this.state.game} />)
            case('score'):
                return(<Score data={data} game={this.state.game} user={this.props.user} />)
            default: 
                return null;
        }
    }

    render(){
        if(this.state.incorrect_game_id) return <Redirect to="/intro" />
        if(this.state.loading || this.props.user.user === null) return <LoadingScreen />

        return(
            <GameData game={this.state.game} user={this.props.user} >
                <GameContext.Consumer>
                    {data => this.renderGame(data)}
                </GameContext.Consumer>
            </GameData>
        )
    }
}

export default Game;