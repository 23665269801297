import React from 'react';
import NextButton from '../global/NextButton';


class TextTemplate extends React.Component {
    render(){
        var d = this.props.screen.templateData;
        return (
            <div className="template template--text">
                <h1>{d.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: d.body }} />
                <NextButton {...this.props} />
            </div>
        )
    }
}

export default TextTemplate;