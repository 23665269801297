import React from 'react';

import TemplateRender from '../../components/templates/TemplateRender';
import GameMenu from '../../components/global/GameMenu';

class PostGame extends React.Component {

    _next(){
        this.props.data.set_game_state('score');
    }

    render(){
        const {data, game} = this.props;
        const screen = data.failed ? game.screens.failed_screen : game.screens.succes_screen;

        return(
            <React.Fragment>
                <GameMenu />
                <TemplateRender {...this.props} next={() => this._next()} screen={screen} />
            </React.Fragment>
        );
    }
}

export default PostGame;

