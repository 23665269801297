import React from 'react';

class NextButton extends React.Component {
    render(){
        var d = this.props.screen.templateData;
        var label = 'Verder';
        console.log(d)
        if(typeof d.disable_next_button !== 'undefined') return null;
        if(typeof d.next_button_label !== 'undefined') label = d.next_button_label;

        return (
            <button className="btn btn-primary" onClick={() => this.props.next()}>{label}</button>
        )
    }
}

export default NextButton;