import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../components/global/LoadingScreen';
import Logo from '../assets/images/get-lost-logo.png';
import MenuBar from '../components/global/MenuBar';

class Intro extends React.Component{


    constructor(){
        super();

        this.state = {
            loading: false,
            game_id: '',
            game_slug: null,
            error: null,
            intro_progress: 1,
            redirect_to_game: false,
        }
    }

    componentDidMount(){
        console.log(this.props);
        // this.props.user.signInAnonymously();
    }

    _nextStep(){
        this.setState({intro_progress: this.state.intro_progress + 1})
    }

    _handleStart(){
        var game_id = this.state.game_id
        // Set loading state
        this.setState({loading: true})
        // Check if game exists
        var json;
        try {
            json = require(`../assets/data/game_${game_id}.json`);
        }
        catch(e) {
            if(e.code === 'MODULE_NOT_FOUND'){
                // Game bestaat niet, handle error
                this.setState({loading: false, error: 'Dit spel bestaat helaas niet, weet je zeker dat je de pin correct overgenomen hebt?'});
                return;
            };
            json = null;
        }

        // Create game data
        this.props.user.createGameData(json)
        .then(() => {

            // After creact game redirect to game url
            this.setState({redirect_to_game: true, game_slug: json.config.game_slug})
        });
    }
    
    _onValueChange(e){
        const value = e.target.value
        const name = e.target.name

       this.setState({[name]: value})
    }

    render(){
        if(!this.props.user.isAuthenticated){
            return(
                <Redirect to="/login" />                
            )
        }
        if(this.state.redirect_to_game){
            return(
                <Redirect to={`/${this.state.game_slug}?game_id=${this.state.game_id}`} />
            )
        }

        if(this.state.loading) return <LoadingScreen />

        return(
            <React.Fragment>
                <MenuBar />
                <div className="container intro">
                    <div className="intro--1">
                        <img src={Logo} alt="Get Lost logo" className="logo" />
                        <p>Jullie staan op het punt om Get Lost te spelen, gaaf! Voer hieronder de pin in die jullie toegang geeft tot het spel. Deze zal door een organisator aan jullie worden doorgegeven.</p>
                        <div className="input input--text">
                            <input type="number" pattern="[0-9]*" inputMode="numeric" name="game_id" value={this.state.game_id} onChange={(e) => this._onValueChange(e)} placeholder="123456"/>   
                            { this.state.error !== null 
                                ? <div className="error">{this.state.error}</div>         
                                : null
                            }
                        </div>
                        <div className="btn--group">
                            <button className="btn btn-primary" onClick={() => this._handleStart()}>Let's start</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Intro