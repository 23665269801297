import React from 'react';
import helper from '../../util/helpers';

class TimerReveal extends React.Component {

    constructor(){
        super();
        this.state = {
            time: null,
            reveal_state: 'animate_in',
        }
    }

    componentDidMount(){
        const d = this.props.game.config;
		const start_time = new Date(this.props.data.start_time);
		// const time_limit = new Date(d.time_limit);

        var end_time = start_time.setMinutes(start_time.getMinutes() + d.time_limit);

		this.timerInt = setInterval(() => {
            var now = new Date();
            var timer = end_time - now
			const time = new Date(timer);
			this.setState({time: time});

        }, 1000)
        
        setTimeout(() => {
            this.setState({reveal_state: 'animate_out'});
        }, 5000)

        setTimeout(() => {
            this.props.next();
        }, 8000)
    }
    componentWillUnmount(){
		clearInterval(this.timerInt)
    }
    
    render(){
        if(this.state.time === null) return null;
        var timestring = helper.get_timestring(this.state.time);
    
        return(
            <div className={`score_reveal timer_reveal ${this.state.reveal_state}`}>
                <span>De tijd dringt.</span>
                <h1>{timestring}</h1>
                {/* <p>Je weet pas of je hebt   gewonnen als iedereen het spel heeft afgerond.</p> */}
            </div>
        )
    }
}

export default TimerReveal;