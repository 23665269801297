import React from 'react';
import UserContext from './UserContext';
import { withRouter } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


class UserData extends React.Component {
    constructor(props){
        super(props);

        this.signInAnonymously = () => {
            return new Promise((resolve, reject) => {

                firebase.auth().signInAnonymously().then((user) => {
                    this.setState({isAuthenticated: true, user: user.user}, () => resolve(this.state));
                })

            })
        }

        this.signOut = () => {
            return new Promise((resolve, reject) => {
                firebase.auth().signOut().then(() => resolve())
            })
        }

        this.createGameData = (game) => {
            return new Promise( async (resolve, reject) => {
                var game_id = game.config.game_id;
                var game_slug = game.config.game_slug;
                var title = game.config.title;
                var user_data_set = false;
                var game_data_set = false;

                var user_ref = firebase.database().ref(`users/${this.state.user.uid}/savegames/${game_id}`);
                var game_ref = firebase.database().ref(`games/${game_id}`);
                
                var game_data = await game_ref.once('value').then((snap) => (snap.val()));

                // Reject if game status is not active
                if(game_data.status !== 'active'){
                    reject('Game is niet live');
                    return;
                }                

                // Check if user data is already set
                var user_data = await user_ref.once('value').then((snap) => (snap.val()));
                
                if(user_data !== null) {
                    // game staat er al in
                    resolve();
                    return;
                }

                var route_count = game.config.game_routes_count;
                var player_count = game_data.player_count;
                var user_route = route_count === 1 ? 0 : calc_user_route(player_count, route_count);

                // Set first user data
                user_ref.set({
                    progress: 0,
                    game_id: game_id,
                    title: title,
                    game_state: 'pregame',
                    route: user_route,
                }, (err) => {
                    if(err) console.log('user_ref_error', err);

                    user_data_set = true;
                    check_all_set();
                })


                game_ref.update({
                    player_count: game_data.player_count + 1
                }, (err) => {
                    if(err) console.log('game_ref_update_err', err);

                    game_data_set = true;
                    check_all_set();
                })

                // Set active game to local storage          
                var active_game = {
                    game_slug: game_slug,
                    game_id: game_id
                }
    
                localStorage.setItem('active_game', JSON.stringify(active_game));

                // Check if all async actions are done then resolve promise
                function check_all_set(){
                    if(user_data_set && game_data_set){
                        resolve();
                    }
                }

            })
        }

        this.setUserValue = (key, value) => {
            return new Promise((resolve, reject) => {
                this.setState({[key]: value}, () => {
                    console.log(this.state);
                    resolve(this.state)
                })
            })
        }

        this.state = {
            isAuthenticated: false,
            user: this.props.user,
            
            // actions
            signInAnonymously: this.signInAnonymously,
            signOut: this.signOut,
            setUserValue: this.setUserValue,
            createGameData: this.createGameData,
        }
    }

    render(){
        return(
            <UserContext.Provider value={this.state} >
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export default withRouter(UserData);



function calc_user_route(player_count, route_count){
    var user_route;
    for (var route = 0; route < route_count; route++) {
        user_route = false;
        var i = 0;
        while(user_route === false){
            if(route + (i * route_count) === player_count){
                user_route = route;
                return user_route;
            }
            if(i > (player_count / route_count)){
                user_route = 'error';
            }
            i++
        }
    }
}