import React from 'react';

class Timer extends React.Component {
    constructor(props){
		super(props)

		this.state = {
			visible: this.check_if_timer_visible(),
			timer: null,
			redirect: false,
		}
	}

    check_if_timer_visible(){
        var timer_active_from = this.props.game.config.timer_active_from;
        var progress = this.props.data.progress;
        if(timer_active_from <= progress){
            return true;
        }
        return false;
    }

    componentDidMount(){
        if(this.state.visible){
            this.timer();
        }
    }
    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            if(this.check_if_timer_visible()){
                this.timer();
            }
        }
    }

    timer(){
        window.document.body.classList.add('timer_active');
        const d = this.props.game.config;
		const start_time = new Date(this.props.data.start_time);
		// const time_limit = new Date(d.time_limit);

        var end_time = start_time.setMinutes(start_time.getMinutes() + d.time_limit);

		this.timerInt = setInterval(() => {
            var now = new Date();
            var timer = end_time - now
			const time = new Date(JSON.parse(timer))
			// if(time.getUTCMinutes() < 1 && time.getUTCSeconds() <= 10){
			// 	var sound = new Howl({
			// 	  src: ['/assets/Timer.wav'],
			// 	  loop: false,
			// 	});		
			// 	sound.play()		
			// }			

            this.setState({timer: JSON.stringify(timer), visible: true})
            if(time < 0){
    		    this.stopTimer();            
                this.props.timeup();
            }

		}, 1000)
    }
    stopTimer(){
        clearInterval(this.timerInt)
    }
	componentWillUnmount(){
		this.stopTimer();
	}

	render(){
		if(this.state.visible === true){
			const time = new Date(JSON.parse(this.state.timer))
			var hours = JSON.stringify(time.getUTCHours())
			if(hours < 10){
				hours = '0' + hours
			}
			var min = JSON.stringify(time.getUTCMinutes())
			if(min < 10){
				min = '0' + min
			}
			var sec = JSON.stringify(time.getUTCSeconds())
			if(sec < 10){
				sec = '0' + sec
			}
			return(
				<div className="timer">
					{hours}:{min}:{sec}
				</div>
			)
		}
		
		return null
		
	}
}
export default Timer;   