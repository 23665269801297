import React from 'react';
import { Link } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/database';

class OldScores extends React.Component {
    
    constructor(){
        super();
        this.state = {
            scores: [],
        }
    }
    componentDidMount(){
        var user_ref = firebase.database().ref(`users/${this.props.user.user.uid}/savegames`);
        user_ref.once('value').then((snap) => {
            var res = snap.val();

            var arr = [];

            for(var key in res){
                var json = require(`../../assets/data/game_${res[key].game_id}.json`);
                res[key].game_slug = json.config.game_slug;
				arr.push(res[key])
            }
            
            this.setState({scores: arr})
            

        })
    }
    render(){
        if(this.state.scores.length > 0){
            return(
                <div className="played_games">
                    <h2>Gespeelde spellen</h2>
                    {this.state.scores.map((score, index) => (
                        <div key={index} >
                            <h4>{score.title}</h4>
                            <Link to={`/${score.game_slug}?game_id=${score.game_id}`}>Bekijk scoreboard</Link>
                        </div>
                    ))}
                </div>
            )
        }

        return null;
    }
}

export default OldScores