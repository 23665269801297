import React from 'react';

class Hints extends React.Component{
    
    constructor(){
        super();
        this.state = {
            hint: null,
            hint_used: false,
        }
    }
    componentDidMount(){
        if(typeof this.props.screen.templateData.hints === 'undefined') return;

        this.hint_interval = setInterval(() => {
            this.can_user_have_hint();
        }, 1000)

    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            console.log('did update');
            console.log(this.props.screen.templateData);
            
            if(typeof this.props.screen.templateData.hints === 'undefined'){
                clearInterval(this.hint_interval);
                return;
            };

            // Clear prev interval
            clearInterval(this.hint_interval);

            // Create new interval with new screen in props
            this.hint_interval = setInterval(() => {
                this.can_user_have_hint();
            }, 1000)
        }
    }

    componentWillUnmount(){
        clearInterval(this.hint_interval);
    }

    can_user_have_hint(){
        if(this.state.hint_used){
            clearInterval(this.hint_interval);
            return;
        }


        var hint_used = this.props.data.screens[this.props.data.progress].hint_used;
        if(hint_used){
            clearInterval(this.hint_interval);
            return;
        }

        if(typeof this.props.screen.templateData.hints === 'undefined') return;

        
        // Check if screen has hints;
        var start_time = new Date(this.props.data.screens[this.props.data.progress].start_time);
        var now = new Date();
        var time_in_screen = new Date(now - start_time).getMinutes();
        console.log(time_in_screen)
        this.props.screen.templateData.hints.forEach(hint => {
            if(hint.minutes_till_hint <= time_in_screen){
                this.give_hint(hint);
            }
        });
    }

    give_hint(hint){
        this.setState({hint: hint, hint_used: true});
    }

    async close_hint_modal(){
        var hint_used = await this.props.data.set_hint_used_at_screen(this.props.data.progress);
        this.setState({hint: null, hint_used: true});
    }

    render(){
        if(this.state.hint !== null){
            return(
                <div className="modal__outer">
                    <div className="modal__background" onClick={() => this.close_hint_modal()}/>
                    <div className="modal__inner">
                        <h2>Hint</h2>
                        <p>{this.state.hint.body}</p>
                        <button className="btn" onClick={() => this.close_hint_modal()}>Okay</button>
                    </div>
                </div>
            )
        }

        return null;
        
    }
}

export default Hints;