import React from 'react';
import { Redirect } from 'react-router-dom';
import TemplateRender from '../components/templates/TemplateRender';
import UserContext from '../util/UserContext';
import GameMenu from '../components/global/GameMenu';

class Live extends React.Component {

	static contextType = UserContext;    

    constructor(props){
        super(props);
        this.state = {
            screen: null,
            progress: 0,
            game_id: props.match.params.id,
            screens: null,
            config: null,
            loading: true,
        }
    }

    componentDidMount(){
        if(typeof this.state.game_id === 'undefined') return;

        var json;

        try {
            json = require(`../assets/data/game_${this.state.game_id}.json`);
        }
        catch(e) {
            if(e.code === 'MODULE_NOT_FOUND') alert('Deze game staat niet in ons systeem.');
            json = null;
        }

        if(json !== null){
            this.setState({screens: json.screens, config: json.config, screen: json.screens[0], loading: false}, () => console.log(this.state))
        }

    }

    _next(){
        var progress = this.state.progress + 1

        if(this.state.screens.length <= progress){
            this._finish();
            return;
        }

        this.setState({progress: progress, screen: this.state.screens[progress]}, () => console.log(this.state))
    }

    _finish(){
        alert('joe finish')
    }

    render(){
        console.log(this.context)
        if(this.context.isAuthenticated !== true) return <Redirect to="/intro" />
        if(this.state.loading === true) return null;

        return(
            <React.Fragment>
                <GameMenu />
                <TemplateRender next={() => this._next()} screen={this.state.screen} />
            </React.Fragment>
        );
    }
}

export default Live;
