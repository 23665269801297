import React from 'react';

class CodeInput extends React.Component{
    static defaultProps = {
        type: "text",
        code: "1234",
    }

    constructor(){
        super();
        this.state = {
            value: '',
            inCorrect: false,
        }
    }

    _onChange(e){
        this.setState({value: e.target.value});
    }

    _onSubmit(e){
        
        if(typeof e !== 'undefined') e.preventDefault();

        var entered_value = this.state.value.toUpperCase().replace(' ', '');
        var target_values = this.props.code.split(',');
        var index = target_values.findIndex((value) => {
            return value.toUpperCase().replace(' ', '') == entered_value;
        })

        if(index >= 0){
            this.props.onCorrect();
        }else{
            this.props.onIncorrect();
            this.setState({value: '', inCorrect: true});
            setTimeout(() => {
                this.setState({inCorrect: false})
            }, 400);
        }
        
    }

    render(){
        var input_class = this.state.inCorrect ? 'incorrect' : '';

        return(
            <form className={`input--code ${input_class}`} onSubmit={(e) => this._onSubmit(e)}>
                <div className="input">
                    <input type={this.props.type} value={this.state.value} onChange={(e) => this._onChange(e)} />
                </div>
                <button className="btn btn-primary" onClick={() => this._onSubmit()}>Check</button>
            </form>
        )
    }

}

export default CodeInput